<template>
  <Loader v-if="isRatingPending" />
  <RatingStarts
    v-else
    :value="value"
    :isEditable="isEditable"
    @change="changeValue"
  />
</template>
<script>
import { Digit, RATING } from '@/constants'

export default {
  name: RATING,
  components: {
    RatingStarts: () => import('@/components/atoms/RatingStarts'),
    Loader: () => import('@/components/Loader'),
  },
  props: {
    isEditable: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [Number, String],
      default: Digit.NOUGHT,
    },
    isRatingPending: {
      type: Boolean,
      default: false,
    },
    changeValue: {
      type: Function,
      default: () => {},
    },
  },
}
</script>
